<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Buses Tracking</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item active">Buses</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-lg-12">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">List All</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-bordered table-striped verticle-middle table-responsive-sm"
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Bus Tracking ID</th>
                    <th scope="col">Bus Supervisors</th>
                    <th scope="col">Bus Drivers</th>
                    <th scope="col">Map</th>
                  </tr>
                </thead>
                <tbody v-if="childrens">
                  <tr v-for="student in childrens" :key="student.id">
                    <td>{{ student.name }}</td>
                    <td>
                      <span v-if="student.bus">
                        {{ student.bus.bus_tracking_id }}
                      </span>
                    </td>
                    <td>
                      <span v-if="student.bus && student.bus.supervisors">
                        <ul>
                          <li
                            v-for="supervisor in student.bus.supervisors"
                            :key="supervisor.id"
                            style="list-style: unset; margin-left: 10px"
                          >
                            {{ supervisor.code }}
                            {{ supervisor.firstname }}
                            {{ supervisor.middlename }}
                            {{ supervisor.lastname }}
                            <br />
                            {{ supervisor.phone }}
                          </li>
                        </ul>
                      </span>
                    </td>
                    <td>
                      <span v-if="student.bus && student.bus.drivers">
                        <ul>
                          <li
                            v-for="driver in student.bus.drivers"
                            :key="driver.id"
                            style="list-style: unset; margin-left: 10px"
                          >
                            {{ driver.code }}
                            {{ driver.firstname }}
                            {{ driver.middlename }}
                            {{ driver.lastname }}
                            <br />
                            {{ driver.phone }}
                          </li>
                        </ul>
                      </span>
                    </td>
                    <td>
                      <router-link
                        v-if="student.bus"
                        :to="{
                          name: 'Parent-Buses-Single',
                          params: { id: student.bus.id },
                        }"
                        title="View"
                        ><i class="fa fa-eye color-muted"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td style="text-align: center" colspan="5">
                      No Data Available.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useBuses from "@/composables/buses";
import { inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const hasAnyRole = inject("hasAnyRole");
    const router = useRouter();

    const { getChildrenBuses, childrens } = useBuses();

    onBeforeMount(() => {
      //first: check the user Permission
      if (!hasAnyRole("Parent,Student")) {
        router.push({ name: "401" });
      } else {
        getChildrenBuses();
      }
    });

    return {
      childrens,
    };
  },
};
</script>
